import React from 'react';
import { Box, Typography } from '@mui/material';

import HeroComp from "./Hero";
import TabComp from './tab-comp';
import carImg from '../../assets/imgs/white-car.png';
import foodPackImg from '../../assets/imgs/food-pack.png';
import foodComboImg from '../../assets/imgs/Fast-food-combo.png';
import doublePhoneImg from '../../assets/imgs/doublephone.png';
import coffeeCup from '../../assets/imgs/coffee-cup.png';
import sandwitch from '../../assets/imgs/sandwitch.png';
import ziggySvg from '../../assets/icons/ziggy.svg';
import maleProfileImg from '../../assets/imgs/profile-img1.png';
import femaleProfileImg from '../../assets/imgs/profile-img-2.png';
import ReviewCarousel from './ReviewCarousel';
import FaqComp from './Faqs';
import Footer from '../app/Footer';
import BusinessComp from './business-comp';
import { IGradientCard } from './Gradient-card';
import GradientCarousel from './GradientCarousel';
import AppTab from '../../shared/App-tab';
import FlowStepsComp from './FlowStepsComp';



interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography
                
            >{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const HomeComp = () => {
    const [tabValue, setTabValue] = React.useState(0);

    const reviewSlides = [
        {
            id: 1,
            reviewerName: 'Bishop Chinedum',
            rating: 5,
            profileImg: maleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
        {
            id: 2,
            reviewerName: 'Oluwa sarah',
            rating: 5,
            profileImg: femaleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
        {
            id: 3,
            reviewerName: 'Blake Chinonso',
            rating: 5,
            profileImg: maleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
        {
            id: 4,
            reviewerName: 'Michael Ozor',
            rating: 5,
            profileImg: maleProfileImg,
            content: 'This is a wonderful experience. before i could finish my bath, the rider has arrived with my food.thank you 60secs'
        },
    ];

    const gradientSlides: IGradientCard[] = [
        {
            title: 'Drivers earn up to $10 every day',
            cardImg: carImg,
        },
        {
            title: 'New members save up to $10 on first order',
            cardImg: foodComboImg,
        },
        {
            title: 'You can enjoy free delivery on groceries',
            cardImg: foodPackImg,
        },
    ]

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };


    return (
        <>
            <HeroComp />

            {/* tab section */}
            <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:8/12 my-12">
                <div className="flex justify-center mb-12 mt-20">
                    <p className="text-2xl font-semibold text-center w-10/12 md:w-6/12">Your go-to platform for essential services and earning opportunities, all in one App</p>
                </div>

                <div className="my-12">
                    <AppTab
                        direction={"center"}
                        variant={'fill'}
                        size={'lg'}
                        tabs={[
                            {
                                title: "CUSTOMERS",
                                children: <TabComp type={'customer'} />
                            },
                            {
                                title: "DRIVERS",
                                children: <TabComp type={'drivers'} />
                            },
                            {
                                title: "VENDORS",
                                children: <TabComp type={'vendors'} />
                            },
                            
                        ]}
                    />
                </div>
            </div>

            <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                { /** gradient card */}
                <GradientCarousel 
                    items={gradientSlides} 
                    slidesToShow={1}
                    slidesToScroll={1}
                />
            </div>

            {/* Business section */}
            <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                <BusinessComp />
            </div>
            {/* Business section */}


            <div className="bg-gradient-to-r from-[#4D0481] to-[#10011B] min-h-[400px] relative">
                <img src={coffeeCup} alt="coffee-cup" className="absolute bottom-0 left-0 w-[50px] md:w-[100px]" />
                <img src={sandwitch} alt="sandwitch" className="absolute bottom-0 right-0 w-[50px] md:w-[100px]" />
                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="my-12">
                            
                            <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-semibold mb-4">60secs has you covered, anytime , any day!</p>

                            <p className="text-white mt-12 mb-4">
                                We believe deeply in driving social and economic progress across the Nigeria. 
                                We use our app to connect customers to the communities that need the most support and provide them with seamless delivery platform.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <img src={doublePhoneImg} alt="car-img" className="" />
                        </div>
                    </div>
                </div>
            </div>

            <FlowStepsComp />

            <div>
                <div className="my-12">
                    <p className="text-3xl font-semibold flex justify-center">Reviews from our users</p>
                    <div className="flex justify-center mt-4">
                        <img src={ziggySvg} alt="ziggy-svg" />
                    </div>
                </div>

                <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:8/12 my-12">
                    <ReviewCarousel 
                        items={reviewSlides} 
                        slidesToShow={3}
                        slidesToScroll={1}
                    />
                </div>
            </div>


            <div>
                <div className="my-12">
                    <p className="text-3xl font-semibold flex justify-center">FAQ and Answers</p>
                    <div className="flex justify-center mt-4">
                        <img src={ziggySvg} alt="ziggy-svg" />
                    </div>
                </div>

                <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:8/12 my-12">
                    <FaqComp />
                </div>
            </div>

            <div className="mt-40">
                <Footer />
            </div>
        </>
    )
}

export default HomeComp;