import { FC, ReactNode, useState, useEffect } from "react";

export type Tab = {
    children: ReactNode;
    title: string;
}

type Props = {
    variant?: "pill" | 'underline' | 'fill'
    tabs: Tab[];
    tabIndex?: number,
    direction?: 'left' | 'center' | 'right',
    size?: 'sm' | 'lg'
}

const AppTab: FC<Props> = ({ 
    tabs, 
    variant = 'pill', 
    tabIndex = 0, 
    direction = 'left',
    size = 'sm'
}) => {
    const [activeTab, setActiveTab] = useState<Tab | null>( null );

    useEffect(() => {
        if(tabs.length > 0){
            setActiveTab(tabs[tabIndex])
        }
    }, [variant, tabs, tabIndex]);


    return tabs.length <= 0 ? (null) : (
        <>
            <div className="my-4 w-full">
                <div className={`
                    flex gap-2 mb-8 ${ direction === 'left' ? 'justify-start' : direction === 'center' ? 'justify-center' : 'justify-end'}    
                `}>
                    {
                        tabs.map((tab: Tab, idx: number) => (
                            <div key={idx}>
                                <div 
                                    className={`w-max ${ size === 'sm' ? 'px-4 py-1' : 'px-8 py-4'} text-center rounded-[30px] text-[12px] cursor-pointer
                                        ${ 
                                            ((variant === 'pill') && (activeTab?.title === tab.title)) 
                                                ? 'bg-purpleAlert text-primary'
                                                : ((variant === 'fill') && (activeTab?.title === tab.title)) 
                                                ? 'bg-primary text-white'
                                                : ((variant === 'underline') && (activeTab?.title === tab.title))
                                                ? 'underline text-primary'
                                                : 'text-darkGray'
                                        }`}
                                    onClick={() => setActiveTab(tab)}
                                >{tab.title}</div>
                            </div>
                        ))
                    }
                    
                </div>

                <div className="mt-8 mb-2">
                    { activeTab && activeTab.children }
                </div>
            </div>
        </>
    )
}

export default AppTab;