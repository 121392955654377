import { FC } from 'react';
import { Button } from '@mui/material';

import { BusinessData } from '../../../constants';
import ziggySvg from '../../../assets/icons/ziggy.svg';

type CardData = {
    img: string;
    title: string;
    content: string;
}

type CardProps = {
    item: CardData
}

const BusinessCardComp: FC<CardProps> = ({ item }) => {
    return (
        <>
            <div className="bg-white shadow-md rounded-md w-full">
                <div>
                    <img src={ item.img } width={'100%'} className="rounded-md" alt="img" />
                </div>

                <div className="p-8">
                    <div className="my-4">
                        <p className="text-[#121212B2] font-semibold">{ item.title }</p>
                    </div>

                    <div className="my-4">
                        <p className="text-[#121212B2]">
                            { item.content }
                        </p>
                    </div>

                    <div className="my-4 flex justify-center">
                        <Button
                            sx={{
                                width: '80%',
                                fontSize: '11px'
                            }}
                            className="relative"
                            variant={'contained'}
                            color={'primary'}
                            style={{
                                backgroundColor: '#5C188B',
                                color: '#ffffff',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                gap: 4,
                                padding: '1rem'
                            }}
                        >
                            <span>Register now</span>
                        </Button>
                    </div>
                </div>
                
            </div>
        </>
    )
}

const BusinessComp: FC = () => {
    return (
        <>
            <div className="w-full">
                <div className="flex justify-center">
                    <div>
                        <p className="text-3xl mb-4">Let's achieve it together</p>
                        <div className="flex justify-center">
                            <img src={ziggySvg} alt="ziggysvg" />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 my-20">
                    {
                        BusinessData.map((item: CardData, idx: number) => (
                            <div key={idx}>
                                <BusinessCardComp item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default BusinessComp;