import { Datepicker } from 'flowbite';
import type { DatepickerOptions, DatepickerInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import { FC } from 'react';


const DatePickerComp: FC = () => {
        // set the target element of the input field or div
    const $datepickerEl: HTMLInputElement = document.getElementById('datepicker-custom') as HTMLInputElement;

    // optional options with default values and callback functions
    const options: DatepickerOptions = {
        defaultDatepickerId: null,
        autohide: false,
        format: 'mm/dd/yyyy',
        maxDate: null,
        minDate: null,
        orientation: 'bottom',
        buttons: false,
        autoSelectToday: 0,
        title: null,
        rangePicker: false,
        onShow: () => {},
        onHide: () => {},
    };

    // instance options object
    const instanceOptions: InstanceOptions = {
    id: 'datepicker-custom-example',
    override: true
    };

    /*
    * $datepickerEl: required
    * options: optional
    * instanceOptions: optional
    */
    const datepicker: any = new Datepicker(
        $datepickerEl,
        options,
        instanceOptions
    );

    const getDate = () => {
        // get the currently selected date (undefined if not selected)
        datepicker.getDate();
    }

    const setDate = () => {
        // set the date (or dates if date range picker)
        datepicker.setDate('05/31/2024');
    }

    const showDate = () => {
        // programatically show the datepicker
        datepicker.show();
    }


    const hideDate = () => {
        // programatically hide the datepicker
        datepicker.hide();
    }


   const getParentPicker = () => {
     // use this method to get the parent datepicker instance from https://mymth.github.io/vanillajs-datepicker/#/
     datepicker.getDatepickerInstance();
   }

    return (
        <>
            <div className="relative max-w-sm">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                    </svg>
                </div>
                <input 
                    id="datepicker-orientation" 
                    // datepicker 
                    datepicker-orientation="bottom right" 
                    onClick={() => showDate()}
                    type="text" 
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Select date"
                />
            </div>
        </>
    )
}

export default DatePickerComp;