import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

export type NotificationState = {
    snackBarMessage: string;
    duration: number;
    options: any;
}

type NotificationPayload = { message: string, options?: {}};

const initialState: NotificationState = {
    snackBarMessage: '',
    duration: 4,
    options: {
        preventDuplicate: true
    }
}


export const notificationSlice = createSlice({
    name: 'nofitificationState',
    initialState,
    reducers: {
        OPEN_NOTIFICATION: (state, action: PayloadAction<NotificationPayload>) => {
            state.snackBarMessage = action.payload.message;
            state.options = { ...state.options, ...action.payload.options};
            enqueueSnackbar(state.snackBarMessage, {
                ...state.options,
                ...action.payload.options
            })
        },
        CLOSE_NOTIFICATION: (state) => {
            state.snackBarMessage = '';
            closeSnackbar()
        }
    }
});

export const { OPEN_NOTIFICATION, CLOSE_NOTIFICATION } = notificationSlice.actions;

export default notificationSlice.reducer;