import { FC, useState } from "react";
import AdminLayout from "../../../shared/admin-layout";
import StatCard, { StatCardStatus } from "../../../components/app/StatCard";
import DatePickerComp from "../../../components/form/Datepicker";
import AppTable, { TableHeader } from "../../../shared/app-table";
import LineChart from "../../../components/charts/Linechart";
import Card from "../../../shared/card";


const Dashboard: FC = () => {
    const [tableRows, setTableRows] = useState<any[]>([]);
    const tableHeaders: TableHeader[] = [
        { key: 'orderId', value: 'Order ID' },
        { key: 'type', value: 'Type' },
        { key: 'fullName', value: 'Trip Route' },
        { key: 'email', value: 'Users' },
        { key: 'email', value: 'Rider' },
        { key: 'email', value: 'Pickup Time' },
        { key: 'actions', value: 'Actions' },
    ];


    return (
        <>
            <AdminLayout>
                <div>
                    <div className="mb-4 flex justify-between">
                        <div>
                            <p className="text-lg font-semibold">Dashboard</p>
                        </div>
                        <div className="flex flex-row gap-4">
                            <p className="text-sm">Data Rage: Today</p>
                            <div>
                                <DatePickerComp />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div>
                            <StatCard 
                                title={"Total  Order(s)"}
                                value={"632"}
                                percentage={1.29}
                                status={StatCardStatus.increase}
                            />
                        </div>
                        <div>
                            <StatCard 
                                title={"Total Revenue"}
                                value={"$6,320"}
                                percentage={1.29}
                                status={StatCardStatus.increase}
                            />
                        </div>
                        <div>
                            <StatCard 
                                title={"Pending Complaints"}
                                value={"05"}
                                percentage={1.29}
                                status={StatCardStatus.increase}
                            />
                        </div>
                        <div>
                            <StatCard 
                                title={"Users Today"}
                                value={"600"}
                                percentage={1.29}
                                status={StatCardStatus.increase}
                            />
                        </div>
                    </div>


                    <div className="my-4">
                        <Card type={'sm'} >
                            <LineChart
                                yAxisData={[22000, 38000, 10000, 60000, 55000, 78000, 50000]}
                                xAxisData={['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']}
                                titleText={"Profit  & Revenue"}
                            />
                        </Card>
                    </div>




                    <div className="mt-20">
                        <p className="text-sm">Ongoing Order(s)</p>
                    </div>

                    <div className="my-4 flex justify-start gap-3">
                        <div>
                            <div className="w-max px-4 py-1 text-center rounded-[30px] bg-purpleAlert text-primary text-[12px]">Ride Ordering</div>
                        </div>
                        <div>
                            <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Food Ordering</div>
                        </div>
                        <div>
                            <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Grocery Ordering</div>
                        </div>
                    </div>


                    <div className="mt-12 mb-4">
                        <AppTable 
                            tableHeaders={tableHeaders}
                            tableRows={tableRows}
                        />
                    </div>
                </div>
            </AdminLayout>
        </>
    )
}

export default Dashboard;