import { FC, useEffect, useState } from "react";
import { Button } from '@mui/material';


import handWithPhone from '../../../assets/imgs/hand-with-phone.png';
import playStoreIcon from '../../../assets/icons/playstore-icon.svg';
import appStoreIcon from '../../../assets/icons/apple-icon.svg';
import { AppSteps } from "../../../constants/app-steps";


const FlowStepsComp: FC = () => {


    const [currentIndx, setCurrentIndex] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState<{serialNumber: number, value: string} | null>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prev => {
                const nextIndex = (prev + 1) % AppSteps.length;
                setCurrentStep(AppSteps[nextIndex]);
                return nextIndex;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, [AppSteps.length]);

    return (
        <>
            <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 my-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-8">
                    <div>
                        <img src={handWithPhone} alt="hand with phone" className="rounded-[10px]" />
                    </div>
                    <div className='h-[100%]'>
                        <div className="bg-[#AC4EFE] w-full rounded-[10px] px-8 py-24 h-[100%]">
                            <p className="text-white mb-4">Get started in Five steps</p>

                            <div className="my-8">
                                <span className="bg-[#D9D9D9] text-[#AC4EFE] rounded-full text-[16px] font-bold text-center py-2 px-4">
                                    { currentStep?.serialNumber || 1 }
                                </span>
                            </div>

                            <p className="text-3xl md:text-4xl lg:text-4xl text-white font-semibold my-8">
                                {
                                    currentStep?.value || "Download the app from Google play store or Apple store"
                                }
                            </p>


                            <div className="flex flex-col md:flex-col lg:flex-row gap-4 mt-12">
                                <div>
                                    <Button
                                         sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={appStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on apple store</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        className="relative"
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={playStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on play store</span>
                                    </Button>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FlowStepsComp;