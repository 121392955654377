import { INavbarLink } from "../models";

export const NavBarLinks: INavbarLink[] = [
    {
        name: 'Home',
        path: '/'
    },
    {
        name: 'Services',
        path: '/services'
    },
    // {
    //     name: 'Company',
    //     path: '/company'
    // },
    // {
    //     name: 'Business',
    //     path: '/business'
    // },
];