import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';

import iphoneImg from '../../../assets/imgs/iphone14.png';
import requestImg from '../../../assets/imgs/request-phone.png';
import dishMenuImg from '../../../assets/imgs/dishes-phone.png';
import playStoreIcon from '../../../assets/icons/playstore-icon.svg';
import appStoreIcon from '../../../assets/icons/apple-icon.svg';

interface IUserTab {
    title: string;
    description: string;
    tabImg: string;
    bgColor: string;
}


type Props = {
    data: IUserTab | null
}


const CustomersTab: FC<Props> = ({ data }) => {
    return (
        <>
             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-32">
                <div>
                    <div 
                        className="bg-gradient-to-t from-[#DEC0F7] via-[#DEC0F7] to-[#F4E7FF] pt-12 min-h-[250px] flex justify-center items-baseline w-full rounded-[10px]"
                    >
                        <img src={data?.tabImg || iphoneImg} alt="phone" />
                    </div>
                </div>
                <div>
                    <p className="text-2xl font-semibold mb-4">
                        { data?.title  || 'Rides | Foods | Mart | Pay' }
                    </p>

                    <p className="text-[#12121280] my-8">
                        {
                            data?.description || 'Have your meal delivered to your doorstep, shop from different supermarket next to you and, book a ride to your destination.'
                        }
                    </p>

                    <div className="mt-12 mb-4">
                        <p className="text-xl font-semibold my-3">Try the app today</p>

                        <div className="flex justify-start gap-4 mt-8">
                            <div>
                                <Button
                                    sx={{
                                        fontSize: '11px'
                                    }}
                                    variant={'contained'}
                                    color={'primary'}
                                    style={{
                                        backgroundColor: '#5C188B',
                                        color: '#ffffff',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        gap: 4,
                                        padding: '1rem',
                                    }}
                                    
                                >
                                    <img src={appStoreIcon} alt="playstor-icon" />
                                </Button>
                            </div>
                            <div>
                                <Button
                                    sx={{
                                        fontSize: '11px'
                                    }}
                                    variant={'contained'}
                                    color={'primary'}
                                    style={{
                                        backgroundColor: '#5C188B',
                                        color: '#ffffff',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        gap: 4,
                                        padding: '1rem'
                                    }}
                                >
                                    <img src={playStoreIcon} alt="playstor-icon" />
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const TabData: any = {
    customer: {
        title: 'Rides | Foods | Mart | Pay',
        description: 'Have your meal delivered to your doorstep, shop from different supermarket next to you and, book a ride to your destination.',
        tabImg: iphoneImg,
        bgColor: 'from-[#DEC0F7] via-[#DEC0F7] to-[#F4E7FF]'
    },
    drivers: {
        title: 'Drive with us',
        description: 'Be the boss of your hour and make more money while picking customers and delivery items to them.',
        tabImg: requestImg,
        bgColor: 'from-[#DEC0F7] via-[#DEC0F7] to-[#F4E7FF]'
    },
    vendors: {
        title: 'Grow your business',
        description: 'Market your business to 60secs customers who are willing to  patronise you.',
        tabImg: dishMenuImg,
        bgColor: 'from-[#DEC0F7] via-[#DEC0F7] to-[#F4E7FF]'
    },
}




const TabComp: FC<{type?: string}> = ({ type = 'customer' }) => {
    const [currentData, setCurrentData] = useState<IUserTab | null>(null)
    useEffect(() => {
        if(type){
            setCurrentData(TabData[type])
        }
    }, [type]);
    return (
        <>
             <CustomersTab data={currentData} />
        </>
    )
}

export default TabComp;