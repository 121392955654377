import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Card from '../../../shared/card';
import logo from '../../../assets/imgs/logo-purple.png';
import authBgImg from '../../../assets/imgs/auth-background.png';
import authFooterImg from '../../../assets/imgs/auth-footer.png';
import InputField from '../../../components/form/InputField';
import { Link } from 'react-router-dom';
import AppButton from '../../../components/app/AppButton';


const LoginPage: FC = () => {
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
        remember: false
    });

    const validateForm = () => Yup.object({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    })

    const { values, errors, touched, handleSubmit, handleChange, setSubmitting, isSubmitting, } = useFormik({
        initialValues: formValues,
        isInitialValid: true,
        validationSchema: validateForm(),
        onSubmit: (values) => {
            const payload = {...values}
        }
    });

    useEffect(() => {
        setFormValues({
            email: '',
            password: '',
            remember: false
        })
            
    }, [])


    return (
        <>
            <div className="w-full h-full relative" style={{
                background: `url(${authBgImg})`,
                backgroundSize: "cover",
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="w-full min-h-screen sm:w-11/12 md:w-9/12 lg:w-5/12 mx-auto pt-16">
                    <Card title='' type={'sm'}>
                        <div className="flex justify-center mt-10 mb-4">
                            <img src={logo} width={'100px'} height={"60px"} alt="" />
                        </div>

                        <div className="px-8 mt-8 mb-4">
                            <div className="my-4">
                                <p className="text-lg font-semibold mb-2">Welcome back Admin!</p>
                                <p className="text-sm text-lightGray mb-3">Log in to your account to continue</p>
                            </div>

                            {/** Form section */}
                            <div className="mt-12">
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-4 mb-6">
                                        <InputField
                                            type={'text'}
                                            name='email'
                                            label='Email Address'
                                            labelPosition={'out'}
                                            value={values.email}
                                            isError={(touched.email && errors.email) ? true : false}
                                            errMsg={errors && errors.email}
                                            placeholder='Enter your email'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4 mb-6">
                                        <InputField
                                            type={'password'}
                                            name='password'
                                            label='Password'
                                            labelPosition={'out'}
                                            value={values.password}
                                            isError={(touched.password && errors.password) ? true : false}
                                            errMsg={errors && errors.password}
                                            placeholder='Enter your password'
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="my-4 flex justify-between gap-4">
                                        <div></div>
                                        <div>
                                            <Link 
                                                to={"/admin/forgot-password"}
                                                className="text-sm text-primary"
                                            >FORGOT PASSWORD?</Link>
                                        </div>
                                    </div>
                                    <div className="mt-16 mb-4">
                                        <AppButton 
                                            type={'submit'}
                                            width={"full"}
                                            btnText='Log in'
                                            bgColor={'primary'}
                                            loading={isSubmitting}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="absolute bottom-0 z-10">
                    <img src={authFooterImg} width={"100%"} alt="footer" />
                </div>
            </div>
        </>
    )
}

export default LoginPage;