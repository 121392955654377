import ServicesComp from "../../../components/services-components";


const ServicePage = () => {
    return (
        <>
            <ServicesComp />
        </>
    )
}

export default ServicePage;