

export interface IPolicyData {
    title: string;
    message: string;
}

export const PolicyDataList: IPolicyData[] = [
    {
        title: 'Privacy policy',
        message: `Lorem ipsum dolor sit amet consectetur. Eget tempor porttitor egestas viverra. Purus at orci tristique rutrum suspendisse quis dui diam at. Nec risus praesent malesuada id. Varius enim quisque eu tincidunt eget. Nisl eget arcu auctor quis aliquam scelerisque mauris fermentum. Pulvinar felis tellus praesent volutpat orci. Sed lectus dui dui at. Egestas venenatis faucibus in enim. Phasellus elementum posuere at vel in pretium. Phasellus ornare sit arcu consequat ut sit congue. Duis vulputate etiam suspendisse augue faucibus fermentum sed nunc. Purus quisque nec nunc porta in. Iaculis sodales ut aenean dui enim eget. Tincidunt vitae tempus sagittis nisi commodo malesuada in et. Metus ut a enim ut nunc egestas purus nunc. Mauris posuere pharetra semper vel eleifend. Platea aliquet mauris amet lectus in sed cras tristique. 
        Eget semper tellus duis fringilla sed justo morbi quis tortor. Ac id elementum non donec sit euismod.`
    },
    {
        title: 'Collecting your personal information ',
        message: `Lorem ipsum dolor sit amet consectetur. Eget tempor porttitor egestas viverra. Purus at orci tristique rutrum suspendisse quis dui diam at. Nec risus praesent malesuada id. Varius enim quisque eu tincidunt eget. Nisl eget arcu auctor quis aliquam scelerisque mauris fermentum. Pulvinar felis tellus praesent volutpat orci. Sed lectus dui dui at. Egestas venenatis faucibus in enim. Phasellus elementum posuere at vel in pretium. Phasellus ornare sit arcu consequat ut sit congue. Duis vulputate etiam suspendisse augue faucibus fermentum sed nunc. Purus quisque nec nunc porta in. Iaculis sodales ut aenean dui enim eget. Tincidunt vitae tempus sagittis nisi commodo malesuada in et. Metus ut a enim ut nunc egestas purus nunc. Mauris posuere pharetra semper vel eleifend. Platea aliquet mauris amet lectus in sed cras tristique. 
        Eget semper tellus duis fringilla sed justo morbi quis tortor. Ac id elementum non donec sit euismod.`
    },
    {
        title: 'Understanding the use of cookies',
        message: `Lorem ipsum dolor sit amet consectetur. Eget tempor porttitor egestas viverra. Purus at orci tristique rutrum suspendisse quis dui diam at. Nec risus praesent malesuada id. Varius enim quisque eu tincidunt eget. Nisl eget arcu auctor quis aliquam scelerisque mauris fermentum. Pulvinar felis tellus praesent volutpat orci. Sed lectus dui dui at. Egestas venenatis faucibus in enim. Phasellus elementum posuere at vel in pretium. Phasellus ornare sit arcu consequat ut sit congue. Duis vulputate etiam suspendisse augue faucibus fermentum sed nunc. Purus quisque nec nunc porta in. Iaculis sodales ut aenean dui enim eget. Tincidunt vitae tempus sagittis nisi commodo malesuada in et. Metus ut a enim ut nunc egestas purus nunc. Mauris posuere pharetra semper vel eleifend. Platea aliquet mauris amet lectus in sed cras tristique. 
        Eget semper tellus duis fringilla sed justo morbi quis tortor. Ac id elementum non donec sit euismod.`
    },
    {
        title: 'Use and analysis of your personal information',
        message: `Lorem ipsum dolor sit amet consectetur. Eget tempor porttitor egestas viverra. Purus at orci tristique rutrum suspendisse quis dui diam at. Nec risus praesent malesuada id. Varius enim quisque eu tincidunt eget. Nisl eget arcu auctor quis aliquam scelerisque mauris fermentum. Pulvinar felis tellus praesent volutpat orci. Sed lectus dui dui at. Egestas venenatis faucibus in enim. Phasellus elementum posuere at vel in pretium. Phasellus ornare sit arcu consequat ut sit congue. Duis vulputate etiam suspendisse augue faucibus fermentum sed nunc. Purus quisque nec nunc porta in. Iaculis sodales ut aenean dui enim eget. Tincidunt vitae tempus sagittis nisi commodo malesuada in et. Metus ut a enim ut nunc egestas purus nunc. Mauris posuere pharetra semper vel eleifend. Platea aliquet mauris amet lectus in sed cras tristique. 
        Eget semper tellus duis fringilla sed justo morbi quis tortor. Ac id elementum non donec sit euismod.`
    },
    {
        title: 'Your consent',
        message: `Lorem ipsum dolor sit amet consectetur. Eget tempor porttitor egestas viverra. Purus at orci tristique rutrum suspendisse quis dui diam at. Nec risus praesent malesuada id. Varius enim quisque eu tincidunt eget. Nisl eget arcu auctor quis aliquam scelerisque mauris fermentum. Pulvinar felis tellus praesent volutpat orci. Sed lectus dui dui at. Egestas venenatis faucibus in enim. Phasellus elementum posuere at vel in pretium. Phasellus ornare sit arcu consequat ut sit congue. Duis vulputate etiam suspendisse augue faucibus fermentum sed nunc. Purus quisque nec nunc porta in. Iaculis sodales ut aenean dui enim eget. Tincidunt vitae tempus sagittis nisi commodo malesuada in et. Metus ut a enim ut nunc egestas purus nunc. Mauris posuere pharetra semper vel eleifend. Platea aliquet mauris amet lectus in sed cras tristique. 
        Eget semper tellus duis fringilla sed justo morbi quis tortor. Ac id elementum non donec sit euismod.`
    },
    {
        title: 'How we collect your personal information',
        message: `Lorem ipsum dolor sit amet consectetur. Eget tempor porttitor egestas viverra. Purus at orci tristique rutrum suspendisse quis dui diam at. Nec risus praesent malesuada id. Varius enim quisque eu tincidunt eget. Nisl eget arcu auctor quis aliquam scelerisque mauris fermentum. Pulvinar felis tellus praesent volutpat orci. Sed lectus dui dui at. Egestas venenatis faucibus in enim. Phasellus elementum posuere at vel in pretium. Phasellus ornare sit arcu consequat ut sit congue. Duis vulputate etiam suspendisse augue faucibus fermentum sed nunc. Purus quisque nec nunc porta in. Iaculis sodales ut aenean dui enim eget. Tincidunt vitae tempus sagittis nisi commodo malesuada in et. Metus ut a enim ut nunc egestas purus nunc. Mauris posuere pharetra semper vel eleifend. Platea aliquet mauris amet lectus in sed cras tristique. 
        Eget semper tellus duis fringilla sed justo morbi quis tortor. Ac id elementum non donec sit euismod.`
    },
];