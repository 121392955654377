import { FC, useState, useEffect } from 'react';
import { FaStar, FaRegStar } from "react-icons/fa";



type Props = {
  rate: string | number;
  className?: string
}

const StarRating: FC<Props> = ({ rate, className = "" }) => {
  const  maxRate: number = 5;
  const [filledRate, setFilledRate] = useState<number>(0);
  const [nonFilledRate, setNonFilledRate] = useState<number>(0);

  useEffect(() => {
    if (rate) {
      let newRate: number = typeof rate === "string" ? Math.round(parseInt(rate)) : Math.round(rate);
      
      // Ensure newRate is within 0 to maxRate
      newRate = Math.max(0, Math.min(newRate, maxRate));

      setFilledRate(newRate);
      setNonFilledRate(maxRate - newRate);
    } else {
      setFilledRate(0);
      setNonFilledRate(maxRate);
    }
  }, [rate]);

  return (
    <>
      <div className="flex flex-row">
        {
          Array(filledRate).fill('').map((_, idx) => <FaStar key={idx} size={18} className={className ? className : "text-primary mr-1"} />)
        }
        {
          Array(nonFilledRate).fill('').map((_, idx) => <FaRegStar key={idx} size={18} className={className ? className : "text-primary mr-1"} />)
        }
      </div>
    </>
  )
}

export default StarRating;