import { FC } from "react";
import StarRating from "../../app/StarRating";

import profileImgMale from '../../../assets/imgs/profile-img1.png';
import profileImgFemale from '../../../assets/imgs/profile-img1.png';
import { ReviewItem } from "../ReviewCarousel";

type Props = {
    item: ReviewItem;
}

const ReviewComp: FC<Props> = ({ item }) => {
    return (
        <>
            <div className="bg-white shadow-md p-8 rounded-md w-[400px]">
                <div className="flex justify-start gap-4">
                    <div>
                        <img src={ item.profileImg } width={'100%'} alt="bishop-profile" />
                    </div>
                    <div>
                        <p className="font-semibold">{ item.reviewerName }</p>
                        <StarRating rate={ item.rating } />
                    </div>
                </div>

                <div className="my-4">
                    <p className="text-[#121212B2]">
                        { item.content }
                    </p>
                </div>
            </div>
        </>
    )
}

export default ReviewComp;