import { RiDashboardFill } from "react-icons/ri";
import { SideBarMenuItem } from "../models/sidebar";

export const SideBarMenuItems: SideBarMenuItem[] = [
    {
        pathUrl: '/admin/dashboard',
        title: 'Dashboard',
        icon: <RiDashboardFill />,
        isParent: false,
        sub: []
    }, 
    {
        pathUrl: '/admin/users',
        title: 'User Management',
        icon: <RiDashboardFill />,
        isParent: false,
        sub: []
    }, 
    {
        pathUrl: '/admin/riders',
        title: 'Ride Management',
        icon: <RiDashboardFill />,
        isParent: true,
        sub: [
            {
                pathUrl: '/admin/riders/driver-profiles',
                title: 'Driver Profiles',
                icon: <RiDashboardFill />,
                isParent: false,
                sub: []
            },
            {
                pathUrl: '/admin/riders/ride-tracking',
                title: 'Ride Tracking',
                icon: <RiDashboardFill />,
                isParent: false,
                sub: []
            },
            {
                pathUrl: '/admin/riders/drivers',
                title: 'Driver Profiles',
                icon: <RiDashboardFill />,
                isParent: false,
                sub: []
            },
        ]
    }, 
    {
        pathUrl: '/admin/vendors',
        title: 'Vendors Management',
        icon: <RiDashboardFill />,
        isParent: true,
        sub: [
            {
                pathUrl: '/admin/vendors/profiles',
                title: 'Vendor Profiles',
                icon: <RiDashboardFill />,
                isParent: false,
                sub: []
            },
            {
                pathUrl: '/admin/vendors/ride-tracking',
                title: 'Ride Tracking',
                icon: <RiDashboardFill />,
                isParent: false,
                sub: []
            },
            {
                pathUrl: '/admin/vendors/drivers',
                title: 'Driver Profiles',
                icon: <RiDashboardFill />,
                isParent: false,
                sub: []
            },
        ]
    }, 
    {
        pathUrl: '/admin/bills-payment',
        title: 'Bills Payment',
        icon: <RiDashboardFill />,
        isParent: false,
        sub: []
    }, 
    {
        pathUrl: '/admin/bills-payment',
        title: 'Reports / Analytics',
        icon: <RiDashboardFill />,
        isParent: false,
        sub: []
    }, 
    {
        pathUrl: '/admin/bills-payment',
        title: 'Customer Support',
        icon: <RiDashboardFill />,
        isParent: false,
        sub: []
    }, 
    {
        pathUrl: '/admin/bills-payment',
        title: 'Marketing & Promotion',
        icon: <RiDashboardFill />,
        isParent: false,
        sub: []
    }, 
]