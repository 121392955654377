import { FC } from "react"
import PrivacyPolicyComp from "../../../components/privacy-policy-comp";


const PrivacyPolicy: FC = () => {
    return (
        <>
            <PrivacyPolicyComp />
        </>
    )
}

export default PrivacyPolicy;