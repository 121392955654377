import { FC, ReactNode } from 'react';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';

type Props = {
    children: ReactNode
}

const AdminLayout: FC<Props> = ({ children }) => {
    return (
        <>
            <div className='content-wrapper flex'>
                <div className='flex-2 hidden min-h-screen
                    sm:hidden
                    md:block
                    lg:block'
                >
                    <Sidebar />
                </div>
                <div className='w-full lg:flex-1 bg-[#FAFBFC]'>
                    <div className='px-6'>
                        <Topbar 
                            // profile={profile} 
                            // loading={loadingProfile} 
                        />
                        { children }
                    </div>
                </div>
            </div>
            {/* <LogoutComp /> */}
        </>
    )
}

export default AdminLayout;