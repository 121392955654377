export type Faq = {
    category: string;
    items: FaqOption[]
}

export type FaqOption = {
    question: string;
    answer: string;
}

export const Faqs: Faq[] = [
    {
        category: 'General',
        items: [
            {
                question: 'What is 60secs?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Does 60secs operates in all state?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'How can i become a partner?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'What is 60secs wallet?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Can i use 60secs on the web?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
        ]
    },
    {
        category: 'Oder',
        items: [
            {
                question: 'What is 60secs?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Does 60secs operates in all state?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'How can i become a partner?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'What is 60secs wallet?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Can i use 60secs on the web?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
        ]
    },
    {
        category: 'Charges',
        items: [
            {
                question: 'What is 60secs?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Does 60secs operates in all state?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'How can i become a partner?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'What is 60secs wallet?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Can i use 60secs on the web?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
        ]
    },
    {
        category: 'Payment',
        items: [
            {
                question: 'What is 60secs?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Does 60secs operates in all state?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'How can i become a partner?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'What is 60secs wallet?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Can i use 60secs on the web?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
        ]
    },
    {
        category: 'Withdraw',
        items: [
            {
                question: 'What is 60secs?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Does 60secs operates in all state?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'How can i become a partner?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'What is 60secs wallet?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
            {
                question: 'Can i use 60secs on the web?',
                answer: 'Lorem ipsum dolor sit amet consectetur. Massa massa dictum lorem euismod viverra suspendisse. Nunc vel scelerisque ante euismod ',
            },
        ]
    },
]