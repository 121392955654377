import { FC } from "react";
import { Button } from '@mui/material';

export interface IGradientCard {
    title: string;
    cardImg: string;
}


type Props = {
    data: IGradientCard
}


const GradientCard: FC<Props> = ({ data }) => {
    return (
        <>
            <div className="bg-gradient-to-r from-[#C37FFF] via-[#7e3cb7] to-[#5C188B] rounded-[5px] min-h-[200px]">
                <div className="mx-auto w-[90%] flex justify-between">
                    <div className="">
                        <Button
                            variant={'contained'}
                            color={'primary'}

                            style={{
                                backgroundColor: '#ffffff',
                                color: '#5C188B',
                                borderRadius: '50px',
                                border: '1px solid #ffffff',
                                margin: '2rem 0',
                                fontSize: '12px'
                            }}
                        >
                            Did you know?
                        </Button>

                        <p className="text-lg sm:text-xl md:text-3xl text-white font-semibold">{ data?.title }</p>
                    </div>
                    <div className="flex justify-end items-center">
                        <img src={data.cardImg} alt="car-img" className="w-[80px] md:w-[120px] lg:w-[150px]" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GradientCard;