import { FC, useState } from "react";
import StatCard, { StatCardStatus } from "../../../components/app/StatCard";
import AdminLayout from "../../../shared/admin-layout";
import DatePickerComp from "../../../components/form/Datepicker";
import Card from "../../../shared/card";
import AppTable, { TableHeader } from "../../../shared/app-table";
import AppButton from "../../../components/app/AppButton";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import AppTab from "../../../shared/App-tab";
import AirtimeList from "./Airtime/AirtimeList";
import ElectricityList from "./Electricity/ElectricityLIst";
import DataSubscriptionList from "./DataSubscription/List";
import CableList from "./Cable/CableList";
import BettingList from "./Betting/BettingList";


const BillsPages: FC = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [tableRows, setTableRows] = useState<any[]>([]);
    const tableHeaders: TableHeader[] = [
        { key: 'orderId', value: 'Order ID' },
        { key: 'type', value: 'Type' },
        { key: 'fullName', value: 'Trip Route' },
        { key: 'email', value: 'Users' },
        { key: 'email', value: 'Rider' },
        { key: 'email', value: 'Pickup Time' },
        { key: 'actions', value: 'Actions' },
    ];


    const openCreatePage = () => {
        navigate('/admin/vendor/create');
    }

    return (
        <>
            <AdminLayout>
                <div className="py-4">
                    <div className="mb-4 flex justify-between">
                        <div>
                            <p className="text-lg font-semibold">Bills Payment</p>
                        </div>
                        <div className="flex flex-row gap-4">
                            <p className="text-sm">Data Rage: Today</p>
                            <div>
                                <DatePickerComp />
                            </div>
                        </div>
                    </div>
                    


                    <Card type={"sm"}>
                        <AppTab 
                            direction={"left"}
                            tabs={[
                                {
                                    title: "Airtime",
                                    children: <AirtimeList />
                                },
                                {
                                    title: "Data Subscription",
                                    children: <DataSubscriptionList />
                                },
                                {
                                    title: "Cable Subscription",
                                    children: <CableList />
                                },
                                {
                                    title: "Electricity",
                                    children: <ElectricityList />
                                },
                                {
                                    title: "Sport Betting",
                                    children: <BettingList />
                                },
                            ]}
                        />
                        {/* <div className="flex justify-between">
                            <div className="my-4 flex justify-start gap-4">
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] bg-purpleAlert text-primary text-[12px]">Airtime</div>
                                </div>
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Data Subscription</div>
                                </div>
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Cable Subscription</div>
                                </div>
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Electricity</div>
                                </div>
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Sport Betting</div>
                                </div>
                            </div>

                            <div className="flex gap-2">
                                <div>
                                    <AppButton 
                                        width={"max"}
                                        bgColor={"primary"}
                                        btnText="Add New Vendor"
                                        onClick={openCreatePage}
                                    />
                                </div>
                                <div>
                                    <AppButton 
                                        width={"max"}
                                        fill={'outline'}
                                        bgColor={"gray"}
                                        btnText="Download All"
                                        onClick={() => console.log('Download table data')}
                                    />
                                </div>
                            </div>

                        </div> */}

                    </Card>

                </div>
            </AdminLayout>
        </>
    )
}

export default BillsPages;