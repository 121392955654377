import HomeComp from "../../../components/home-components";


const HomePage = () => {
    return (
        <>
            <HomeComp />
        </>
    )
}

export default HomePage;