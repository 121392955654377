
import businessImg from '../assets/imgs/business-img.png';
import riderImg from '../assets/imgs/rider-img.png';
import teamImg from '../assets/imgs/team-img.png';

export const BusinessData: {
    img: string;
    title: string;
    content: string;
}[] = [
    {
        img: businessImg,
        title: "Grow your business",
        content: "Partner with 60secs and grow your business with every order. Set your own hours, enjoy exclusive benefits, and receive dedicated support."
    },
    {
        img: riderImg,
        title: "Become a rider",
        content: "Join 60secs as a driver or delivery partner and earn with every trip. Work on your schedule, enjoy great rewards, and benefit from expert training."
    },
    {
        img: teamImg,
        title: "Join our team",
        content: "We are exicted to announce our partneship with brandnew supermarket. this is a big update for our customers in 60secs."
    },
]