import { FC } from "react";

import userProfile from '../../assets/imgs/profile-img-2.png';


const TopbarProfile: FC = () => {
    return (
        <>
            <div className="relative inline-block text-left">
                <button 
                    id="dropdownAvatarNameButton" 
                    data-dropdown-toggle="dropdownAvatarName" 
                    aria-expanded="true"
                    aria-haspopup="true"
                    className="flex items-center text-sm pe-1 font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:me-0 dark:focus:ring-gray-700 dark:text-white" 
                    type="button"
                >
                    <span className="sr-only">Open user menu</span>
                        <img className="w-8 h-8 me-2 rounded-full" src={userProfile} alt="user photo" />
                        <span className="text-black">Michael Ozor</span>
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                        </svg>
                </button>

                {/* <!-- Dropdown menu --> */}
                <div 
                    id="dropdownAvatarName" 
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex={-1}
                    className="z-10 hidden origin-top-right absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                >
                    <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                        <div className="font-medium text-black">System Admin</div>
                        <div className="truncate text-black">michaelozor15@gmail.com</div>
                    </div>
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton">
                        <li>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                        </li>
                        <li>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Profile</a>
                        </li>
                        <li>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                        </li>
                    </ul>
                    <div className="py-2">
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>
                    </div>
                </div>
            </div>


        </>
    )
}

export default TopbarProfile;