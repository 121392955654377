import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import logo from '../../../assets/imgs/logo-purple.png';
import authBgImg from '../../../assets/imgs/auth-background.png';
import authFooterImg from '../../../assets/imgs/auth-footer.png';
import AppButton from "../../app/AppButton";
import { VerifyForgotPasswordCodeService } from "../../../services";
import { getItem, setItem } from "../../../utils";
import { ApiResponse } from "../../../models";
import { AxiosError, AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { OPEN_NOTIFICATION } from "../../../store/notification";
import Card from "../../../shared/card";
// import { getItem } from "../../../helpers";


type inputProps={
  type:string,
  placeholder:string,
  focus:boolean,
  value:string,
  id:Number;
}

type Props = {
  changeStep: (data: number) => any
}


const ForgotPasswordComp2 = ({ changeStep }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
    
  const [loading, setLoading] = useState<boolean>(false);
  const [inputs, setInput] = useState<inputProps[]> ([
    {type:'text', placeholder:'0', focus: true, value:'', id: 0},
    {type:'text', placeholder:'0', focus: false, value:'', id: 1},
    {type:'text', placeholder:'0', focus: false, value:'', id: 2},
    {type:'text', placeholder:'0', focus: false, value:'', id: 3},
    {type:'text', placeholder:'0', focus: false, value:'', id: 4},
    // {type:'text', placeholder:'0', focus: false, value:'', id: 5},
  ]);
  const [codes, setCodes] = useState<string[] | any>([]);
  const [resetCode, setResetCode] = useState<string>('');
  const dispatch = useDispatch();

    const changeFocus = () => {
      inputRef.current?.focus();
    }

    const changeInput=(id:Number, value:string)=>{
      const tempInput:inputProps[] = [...inputs];     
      tempInput.forEach((e, i, a) => {
        if(e.id === id){
          if(value !== ''){
            e.value = value;
            setCodes((prev: string[]) => [...prev, value]);
            e.focus = false;
            if(i < (a.length - 1)){
              a[i + 1].focus = true
            }
            // if(i === 4) e.focus = true
          }else{
            e.value = '';
            setCodes(codes.pop());
            if(i >= 1 ) {
              a[i - 1].focus = true;
              e.focus = false;
            }
          }
        }
      });
      setInput(tempInput);
    }

    const handleSubmit = (e: any) => {
      e.preventDefault();
      setLoading(false);
      if(!!resetCode){
        setLoading(true);
        const email = getItem('xxid');
        const data = { email, resetCode };
        VerifyForgotPasswordCodeService(data).then((res: AxiosResponse<ApiResponse>) => {
          setLoading(false);
          const { message } = res.data;
          setItem('clientD', res.data.payload)
          dispatch(OPEN_NOTIFICATION({ message }))
          changeStep(3)
        }).catch((err: AxiosError<ApiResponse>) => {
          setLoading(false);
          if(err.response?.data.message){
            dispatch(OPEN_NOTIFICATION({ message: err.response?.data.message }));
            changeStep(2);
          }
        })
      }
    }

   useEffect(() => {
    let code: string = inputs.map(i => i.value).join('');
    if(code != ''){
      setResetCode(code);
      inputRef && changeFocus();
    }
   },[inputs]);


  return (
    <>
      <div className="w-full h-full relative" style={{
          background: `url(${authBgImg})`,
          backgroundSize: "cover",
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
      }}>
        <div className="w-full min-h-screen sm:w-11/12 md:w-9/12 lg:w-5/12 mx-auto pt-16">
          <Card title='' type={'sm'}>
            {/* Header */}
            <div className="w-full p-4">
              <div className="mx-auto w-8/12">
                <div className="flex justify-center">
                  <Link to={'/'}>
                    <img src={logo} width={'100px'} height={"60px"} alt="" />
                  </Link>
                </div>
                <h1 className="text-2xl lato-bold font-bold text-textBlack text-center">Forgot your password?</h1>
                <p className="text-sm lato-regular text-textBlack mb-4 text-center">
                  Go back to 
                  <Link to={'/admin/login'} className="text-primary font-bold mx-2">Log in</Link>
                </p>
                <p className="text-sm lato-regular text-textBlack mb-4 text-center">
                  kindly enter the six digit code sent to your email.
                </p>
              </div>


              {/* Form */}
            <div className="mt-12">
              <form onSubmit={handleSubmit}>
                
                <div className='my-10'>
                  <h1 className="text-2xl lato-bold font-bold text-lightGray mb-4">Enter OTP</h1>
                  <div className='my-2 flex justify-between'>
                    {inputs.map((e, i)=>{
                        return(
                          <input
                            key={i}
                            type='text'
                            maxLength={1}
                            ref={e.focus ? inputRef : null}
                            autoFocus={true}
                            placeholder="0"
                            onChange={(e)=> { 
                              changeInput(i, e.target.value)
                            }}
                            className="rounded-md w-2/12 h-14 text-center mx-1 my-6  focus:outline-none focus:ring-0 text-textBlack border-lighterGray border-solid border"
                        />
                        )
                    })}
                  </div>
                </div>

                <div className="mt-[25%] mb-4">
                  <div className="flex justify-center">
                    <AppButton 
                      width="full"
                      fill="fill"
                      btnText="Verify"
                      bgColor="primary"
                      loading={loading}
                      type="submit"
                    />
                  </div>
                </div>
                
              </form>

            </div>
            {/* Form */}
            </div>
            {/* Header */}
          </Card>
        </div>

        <div className="absolute bottom-0 z-10">
          <img src={authFooterImg} width={"100%"} alt="footer" />
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordComp2;