import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

//icons
import { CgLogOff } from 'react-icons/cg';
import { IoIosArrowDown } from "react-icons/io";
import { RiDashboardFill } from 'react-icons/ri';

import { SideBarMenuItem } from '../../models';
import logo from '../../assets/imgs/logo.png';
import { OpenLogoutModal } from '../../store/modal/logout-modal';
import { SideBarMenuItems } from '../../constants/sidebar-menu';

type Props = {
    sidebarMenus?: any[]
}

type SidebarNavProps = {
    menuItem: SideBarMenuItem
}


const SidebarNav: FC<SidebarNavProps> = ({ menuItem }) => {
    const [showSubMenu, setShowSubMenu] = useState<boolean>(false); 

    const toggleSidebarNav = () => {
        setShowSubMenu(prev => !prev);
    }
    return (
        <>
            <li className='my-6 py-[10px] px-4 text-center text-white rounded-md hover:bg-[#8652A4] hover:text-white' title={menuItem.title}>
                <Link to={menuItem.pathUrl}>
                    <div className="flex justify-start gap-4">
                        <div className='flex justify-start'>
                            <div>
                                <span><RiDashboardFill size={20} /></span>
                            </div>
                            <div className='mx-2'>
                                <p className="text-sm">{ menuItem.title }</p>
                            </div>
                        </div>

                        {
                            menuItem.isParent && (
                                <div onClick={toggleSidebarNav}>
                                    <span>
                                        <IoIosArrowDown />
                                    </span>
                                </div>
                            )
                        } 

                    </div>
                </Link>
            </li>
        </>
    )
}

const Sidebar = ({ sidebarMenus }: Props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { pathname } = location;

    const openModal = () => {
        dispatch(OpenLogoutModal());
    }

    
    

    return (
        <>
            <div className="bg-gradient-to-b from-[#4D0481] to-[#10011B] h-[100%] px-4 py-5 rounded-tr-[40px]">
                <div className="my-5 px-4">
                    <img src={logo} alt="logo" width="100px" height="100px" />
                </div>
                <ul className="list-none text-[#8c8c8c]">
                    {
                        SideBarMenuItems.length > 0 && SideBarMenuItems.map((menu: SideBarMenuItem, idx: number) => (
                            <SidebarNav 
                                key={idx} 
                                menuItem={menu} 
                            />
                        ))
                    }

                    <li 
                        className={`${ pathname === '/account' && 'bg-[#8652A4] text-white' } mt-20 cursor-pointer my-6 py-3 px-4 text-center rounded-md hover:bg-[#8652A4] hover:text-white` }
                        title="account"
                        onClick={() => openModal()}
                    >
                        <div className='flex justify-start'>
                            <div><span><CgLogOff className='text-xl'/></span></div>
                            <div className='mx-2'>Log Out</div>
                        </div>           
                    </li>       
                    
                </ul>
            </div>
        </>
    )
}

export default Sidebar;