import { FC, useEffect, useState } from "react";

import AppTab from "../../../../shared/App-tab";
import AppTable, { TableHeader } from "../../../../shared/app-table";
import StatusPill, { StatusPillEnum } from "../../../../components/app/StatusPill";

const tableRecords: any[] = [
    {
        orderId: 'ORD1234',
        name: 'MTN 100GB',
        phoneNumber: '09088234245',
        amount: "NGN 20,000",
        status: <StatusPill status={StatusPillEnum.completed} value={'Completed'} />,
        createdAt: '5th Nov 2024',
        actions: '...'
    },
    {
        orderId: 'ORD1234',
        name: 'MTN 100GB',
        phoneNumber: '09088234245',
        amount: "NGN 20,000",
        status: <StatusPill status={StatusPillEnum.pending} value={'Pending'} />,
        createdAt: '5th Nov 2024',
        actions: '...'
    },
    {
        orderId: 'ORD1234',
        name: 'MTN 100GB',
        phoneNumber: '09088234245',
        amount: "NGN 20,000",
        status: <StatusPill status={StatusPillEnum.completed} value={'Completed'} />,
        createdAt: '5th Nov 2024',
        actions: '...'
    },
    {
        orderId: 'ORD1234',
        name: 'MTN 100GB',
        phoneNumber: '09088234245',
        amount: "NGN 20,000",
        status: <StatusPill status={StatusPillEnum.failed} value={'Failed'} />,
        createdAt: '5th Nov 2024',
        actions: '...'
    },
]

const AirtimeList: FC = () => {
    const [tableRows, setTableRows] = useState<any[]>([]);
    const tableHeaders: TableHeader[] = [
        { key: 'orderId', value: 'Order ID' },
        { key: 'name', value: 'Name' },
        { key: 'phoneNumber', value: 'Phone Number' },
        { key: 'amount', value: 'Amount' },
        { key: 'status', value: 'Status' },
        { key: 'createdAt', value: 'Date' },
        { key: 'actions', value: 'Actions' },
    ];

    useEffect(() => {
        setTableRows(tableRecords)
    })

    return (
        <>
            <AppTab 
                direction={'left'}
                variant={"underline"}
                tabs={[
                    {
                        title: "All Order",
                        children: <AppTable tableHeaders={tableHeaders} tableRows={tableRows} />
                    },
                    {
                        title: "Completed",
                        children: <AppTable tableHeaders={tableHeaders} tableRows={tableRows} />
                    },
                    {
                        title: "Pending",
                        children: <AppTable tableHeaders={tableHeaders} tableRows={tableRows} />
                    },
                    {
                        title: "Failed",
                        children: <AppTable tableHeaders={tableHeaders} tableRows={tableRows} />
                    },
                ]}
            />
        </>
    )
}

export default AirtimeList;