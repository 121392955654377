import { FC, useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";



import questionSvg from '../../../assets/icons/questionMark.svg';
import { Faq, FaqOption, Faqs } from '../../../constants';


type FaqItemProps = {
    isActive?: boolean;
    data: FaqOption;
}


const FaqItem: FC<FaqItemProps> = ({ isActive = false, data }) => {
    const [showAnswer, setShowAnswer] = useState<boolean>(false);

    useEffect(() => {
        setShowAnswer(isActive);
    }, [isActive])
    return (
        <>
            <div className="w-full bg-[#D9D9D980] px-6 py-4 my-4 rounded-lg">
                <div className="flex justify-between" onClick={() => setShowAnswer(prev => !prev)}>
                    <div className="flex justify-start gap-2">
                        <div className="my-auto">
                            <img src={questionSvg} width={20}  alt="questionSvg" className="my-auto" />
                        </div>
                        <div>
                            <p className="text-[#121212CC] text-xl">{ data?.question }</p>
                        </div>
                    </div>

                    <div>
                        <IoIosArrowDown size={20} className={`text-[#121212CC] ${!showAnswer && 'rotate-180' }`} />
                    </div>
                </div>

                {
                    showAnswer && (
                        <div className="bg-[#FEF9F9] p-4 my-4 w-full">
                            <p className="text-[#121212CC]">
                                { data?.answer }
                            </p>
                        </div>
                    )
                }
            </div>
        </>
    )
}





const FaqComp: FC = () => {

    const [activeFaq, setActiveFaq] = useState<Faq | null>(Faqs[0]);

    return (
        <>
            <div className="w-full bg-[#F4E7FF33] border-[1px] border-[#C0C5C2] rounded-md py-8 min-h-[400px]">
                <div className="flex flex-col md:flex-row lg:flex-row gap-4">
                    <div className="w-full md:w-2/6 lg:w-1/6">
                        <div className="w-10/12 px-auto mx-auto">
                            <p className="font-semibold">Table of content</p>

                            <div className="my-8 mx-auto flex flex-row gap-4 md:flex-col lg:flex-col overflow-scroll">
                                {
                                    Faqs.map((faq: Faq, idx: number) => (
                                        <div 
                                            key={idx} 
                                            className={`hover:bg-[#831BD5] hover:text-white my-4 w-max ${ activeFaq && (activeFaq.category === faq.category) ? 'bg-[#831BD5] text-white' : 'text-[#12121280]'} rounded-md px-8 py-4`}
                                            onClick={() => setActiveFaq(faq)}
                                        >{faq.category}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-4/6 lg:w-5/6 px-4">
                        {
                            activeFaq && activeFaq.items.map((item: FaqOption, idex: number) => {
                                return (
                                    <FaqItem 
                                        key={idex} 
                                        isActive={idex === 0 ? true : false} 
                                        data={item}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqComp;