import { FC, useState } from "react";
import StatCard, { StatCardStatus } from "../../../components/app/StatCard";
import AdminLayout from "../../../shared/admin-layout";
import DatePickerComp from "../../../components/form/Datepicker";
import Card from "../../../shared/card";
import AppTable, { TableHeader } from "../../../shared/app-table";
import AppButton from "../../../components/app/AppButton";
import { useNavigate } from "react-router-dom";


const RidersDashboard: FC = () => {
    const navigate = useNavigate();
    const [tableRows, setTableRows] = useState<any[]>([]);
    const tableHeaders: TableHeader[] = [
        { key: 'orderId', value: 'Order ID' },
        { key: 'type', value: 'Type' },
        { key: 'fullName', value: 'Trip Route' },
        { key: 'email', value: 'Users' },
        { key: 'email', value: 'Rider' },
        { key: 'email', value: 'Pickup Time' },
        { key: 'actions', value: 'Actions' },
    ];


    const openCreatePage = () => {
        navigate('/admin/riders/create');
    }

    return (
        <>
            <AdminLayout>
                <div className="py-4">
                    <div className="mb-4 flex justify-between">
                        <div>
                            <p className="text-lg font-semibold">Riders Management</p>
                        </div>
                        <div className="flex flex-row gap-4">
                            <p className="text-sm">Data Rage: Today</p>
                            <div>
                                <DatePickerComp />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div>
                            <StatCard 
                                title={"Total  Riders(s)"}
                                value={"632K"}
                                percentage={1.29}
                                status={StatCardStatus.increase}
                            />
                        </div>
                        <div>
                            <StatCard 
                                title={"Total Active  Riders(s)"}
                                value={"400K"}
                                percentage={1.29}
                                status={StatCardStatus.increase}
                            />
                        </div>
                        <div>
                            <StatCard 
                                title={"Total Inactive Riders(s)"}
                                value={"232K"}
                                percentage={1.29}
                                status={StatCardStatus.decrease}
                            />
                        </div>
                        
                    </div>


                    <Card type={"sm"}>
                        <div className="flex justify-between">
                            <div className="my-4 flex justify-start gap-4">
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] bg-purpleAlert text-primary text-[12px]">All Riders</div>
                                </div>
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Active</div>
                                </div>
                                <div>
                                    <div className="w-max px-4 py-1 text-center rounded-[30px] text-darkGray text-[12px]">Inactive</div>
                                </div>
                            </div>

                            <div className="flex gap-2">
                                <div>
                                    <AppButton 
                                        width={"max"}
                                        bgColor={"primary"}
                                        btnText="Add New Riders"
                                        onClick={openCreatePage}
                                    />
                                </div>
                                <div>
                                    <AppButton 
                                        width={"max"}
                                        fill={'outline'}
                                        bgColor={"gray"}
                                        btnText="Download All"
                                        onClick={() => console.log('Download table data')}
                                    />
                                </div>
                            </div>

                        </div>


                        <div className="mt-12 mb-4">
                            <AppTable
                                tableHeaders={tableHeaders}
                                tableRows={tableRows}
                            />
                        </div>
                    </Card>

                </div>
            </AdminLayout>
        </>
    )
}

export default RidersDashboard;