import { configureStore } from "@reduxjs/toolkit";

import appModalReducer from './modal';
import logoutModalReducer from './modal/logout-modal';
import notificationReducer from './notification';

export const store = configureStore({
    reducer: {
        appModal: appModalReducer,
        logoutModal: logoutModalReducer,
        nofitificationState: notificationReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;