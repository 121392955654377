import { useNavigate } from "react-router-dom";

import keyLockSvg from '../../../assets/svg/key-lock.svg';
import authBgImg from '../../../assets/imgs/auth-background.png';
import authFooterImg from '../../../assets/imgs/auth-footer.png';
import AppButton from "../../app/AppButton";
import Card from "../../../shared/card";


const ForgotPasswordCompFour = () => {
  const navigate = useNavigate();
  const goToLogin = () => navigate("/admin/login");

  return (
    <>
      <div className="w-full h-full relative" style={{
          background: `url(${authBgImg})`,
          backgroundSize: "cover",
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
      }}>
          <div className="w-full min-h-screen sm:w-11/12 md:w-9/12 lg:w-5/12 mx-auto pt-16">
            <Card title='' type={'sm'}>
              {/* Header */}
              <div className="w-full p-4">
                <div className="mx-auto w-8/12">
                  <div className="flex justify-center">
                    <img src={keyLockSvg} width={'100px'} height={"60px"} alt="Password reset successful svg" />
                  </div>
                  <h1 className="text-2xl lato-bold font-bold text-textBlack text-center">Password reset successful</h1>
                  <p className="text-sm lato-regular text-textBlack mb-4 text-center">
                    your password has been successfully reset
                  </p>
                </div>


                {/* Form */}
              <div className="mt-12">
                <div className="mt-[25%] mb-4">
                  <div className="flex justify-center">
                    <AppButton 
                      width="full"
                      fill="fill"
                      btnText="Log in"
                      bgColor="primary"
                      type="submit"
                      onClick={goToLogin}
                    />
                  </div>
                </div>
              </div>

              {/* Form */}
              </div>
              {/* Header */}
            </Card>
        </div>

        <div className="absolute bottom-0 z-10">
            <img src={authFooterImg} width={"100%"} alt="footer" />
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordCompFour;