import { FC } from "react";
import Navbar from "../app/Navbar";

import './style.css';
import { IPolicyData, PolicyDataList } from "../../constants/privacy-data";
import FlowStepsComp from "../home-components/FlowStepsComp";
import Footer from "../app/Footer";


const PrivacyPolicyComp: FC = () => {
    return (
        <>
            <div className="wrapper relative min-h-[600px] overflow-hidden">

                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12">
                    <div className="my-8">
                        <Navbar />
                    </div>

                    <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:9/12 mt-16 mb-24">
                        <div className="mx-auto w-11/12 flex flex-col gap-4 mb-12">
                            <h1 className="text-center lato-bold text-white text-4xl sm:text-5xl  md:text-5xl lg:text-6xl font-semibold">
                                PRIVACY POLICY
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-20 mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12">
                {
                    PolicyDataList.map((item: IPolicyData, idx: number) => (
                        <div className="mt-8 mb-12" key={idx}>
                            <p className="mb-8 lato-bold font-bold text-2xl md:text-3xl lg:tex-4xl">{item.title}</p>
                            <p className="lato-regular text-justify text-[14px]">{item.message}</p>
                        </div>
                    ))
                }
            </div>


            <FlowStepsComp />

            <Footer />

        </>
    );
}

export default PrivacyPolicyComp;