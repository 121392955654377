import { FC } from "react";

import './style.css';

import HeroComp from "./Hero";
import eclipse1 from '../../assets/imgs/Ellipse1.png';
import eclipse2 from '../../assets/imgs/Ellipse2.png';
import iphone1 from '../../assets/imgs/newiPhone141.png';
import iphone2 from '../../assets/imgs/newiPhone142.png';
import uxIcon from '../../assets/icons/uxIcon.svg';
import secureIcon from '../../assets/icons/secureIcon.svg';
import fastIcon from '../../assets/icons/fastIcon.svg';
import femaleWithGroceryImg from '../../assets/imgs/woman-with-grocery.png';
import pickupimg from '../../assets/imgs/package-delivered.png';
import FlowStepsComp from "../home-components/FlowStepsComp";
import Footer from "../app/Footer";

const ActionPill: FC<{ text: string}> = ({ text }) => {
    return (
        <>
            <div className="bg-[#F4E7FF] flex justify-start gap-4 p-2 rounded-[5px] w-max my-2">
                <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_3728_7814)">
                        <path d="M15.8542 0.146028C15.9234 0.21536 15.9707 0.303502 15.9903 0.399505C16.0098 0.495508 16.0008 0.595141 15.9642 0.686028L10.1452 15.233C10.0939 15.3611 10.0083 15.4726 9.89771 15.5552C9.78715 15.6378 9.65596 15.6883 9.51856 15.7011C9.38116 15.7139 9.2429 15.6886 9.11897 15.6279C8.99505 15.5672 8.89027 15.4734 8.81618 15.357L5.63818 10.362L0.643176 7.18403C0.526494 7.11001 0.432527 7.0052 0.371646 6.88116C0.310764 6.75711 0.285334 6.61866 0.298161 6.48108C0.310988 6.3435 0.361574 6.21214 0.444337 6.10148C0.5271 5.99083 0.638822 5.9052 0.767176 5.85403L15.3142 0.0370277C15.4051 0.000439957 15.5047 -0.00863977 15.6007 0.0109164C15.6967 0.0304726 15.7848 0.0778028 15.8542 0.147028V0.146028ZM6.63618 10.07L9.39718 14.408L14.1302 2.57603L6.63618 10.07ZM13.4232 1.86903L1.59118 6.60203L5.93018 9.36203L13.4232 1.86903Z" fill="#121212" fill-opacity="0.95" shape-rendering="crispEdges"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_3728_7814" x="-3.70508" y="0.000854492" width="23.7056" height="23.7035" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3728_7814"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3728_7814" result="shape"/>
                        </filter>
                        </defs>
                    </svg>
                </div>
                <div>
                    { text }
                </div>
            </div>
        </>
    )
}

type PlatformCardProps = { 
    img: string; 
    title: string; 
    content: string; 
}

const PlaformFeatureCard: FC<PlatformCardProps> = ({ img, title, content }) => {
    return (
        <div>
            <div className="flex justify-center mb-4">
                <img src={img} alt="platform best img" />
            </div>

            <div>
                <p className="text-center text-xl sm:text-xl md:text-2xl lg:text-2xl font-semibold mb-2">
                    {title}
                </p>
                <p className="mb-2 text-center">{content}</p>
            </div>
        </div>
    )
}


const ServicesComp: FC = () => {
    return (
        <>
            <HeroComp />

            {/* food ordering section */}
            <div className="bg-white min-h-[200px] relative overflow-hidden">
                <img src={eclipse1} alt="coffee-cup" className="absolute -top-60 -left-4 w-[50px] md:w-[170px]" />
                <img src={eclipse2} alt="sandwitch" className="absolute -bottom-44 -right-12 w-[54px] md:w-[250px]" />
                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 py-20">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="my-12">
                            
                            <p className="text-primary">Food Ordering</p>
                            <p className="text-xl sm:text-3xl md:text-3xl lg:text-4xl font-semibold mb-4">
                                Satisfy Your Cravings Anytime, Anywhere
                            </p>

                            <p className="mt-12 mb-4">
                                Enjoy a wide variety of delicious meals delivered to your doorstep from your favorite local restaurants and food vendors. Whether it’s breakfast, lunch, dinner, or a quick snack, we've got you covered.
                            </p>

                            <div className="flex flex-start gap-4">
                                <ActionPill text={"Real-time order tracking."} />
                                <ActionPill text={"Browse menus from diverse cuisines"} />
                            </div>
                            <div>
                                <ActionPill text={"Exclusive discounts and promo codes"} /> 
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <img src={pickupimg} alt="package delivered" className="z-10 w-[60%]" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Grocery gradient section */}
            <div className="bg-gradient-to-b from-[#4D0481] via-[#2c0946] to-[#10011B] min-h-[200px]">

                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 pt-20">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="my-12">
                            
                            <p className="text-white">Groceries Ordering</p>
                            <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-semibold mb-4">Shop Smart, Save Time</p>

                            <p className="text-white mt-12 mb-4">
                                Say goodbye to long queues and heavy shopping bags. Order fresh, high-quality groceries and essentials from trusted stores and have them delivered straight to your home.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <img src={femaleWithGroceryImg} alt="car-img" className="female with grocery" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Ride And Driving section */}
            <div className="bg-[#D9D9D933] min-h-[200px]">

                <div className="">
                    <div className="flex flex-col sm:flex-row">
                        <div className="driver-wrapper w-[45%]">
                        </div>

                        <div className="my-12 flex-grow-1 py-8 px-16">
                            <p className="">Ride Booking</p>
                            <p className="text-xl sm:text-3xl md:text-3xl lg:text-4xl font-semibold mb-2">Get Where You Need to Be</p>

                            <p className=" mt-12 mb-4 w-[70%]">
                                Book safe, reliable, and affordable rides with just a few clicks. Whether it’s a quick errand or a long-distance trip, our platform ensures you reach your destination comfortably.
                            </p>

                            <div>
                                <ActionPill text={"ransparent pricing with no hidden charges."} />
                                <ActionPill text={"Real-time order tracking."} />
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>

            {/* food ordering section */}
            <div className="bg-white min-h-[200px] relative overflow-hidden">
                <img src={eclipse1} alt="coffee-cup" className="absolute -top-60 -left-4 w-[50px] md:w-[170px]" />
                <img src={eclipse2} alt="sandwitch" className="absolute -bottom-44 -right-12 w-[54px] md:w-[250px]" />
                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 py-20">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="my-12">
                            
                            <p className="text-primary">Bills Payment</p>
                            <p className="text-xl sm:text-3xl md:text-3xl lg:text-4xl font-semibold mb-4">
                                Pay Your Bills in Seconds
                            </p>

                            <p className="mt-12 mb-4">
                                Simplify your finances with our easy bill payment service. From utilities to subscriptions, handle it all from one secure platform.
                            </p>

                            <div>
                                <ActionPill text={"Recharge airtime and data bundles"} />
                                <ActionPill text={"Pay electricity, water, and internet bills seamlessly"} />
                                <ActionPill text={"Instant confirmations and receipts"} />
                            </div>
                        </div>
                        <div className="flex justify-center gap-4 mt-12 md:mt-0 lg:mt-0">
                            <img src={iphone1} alt="iphone1" />
                            <img src={iphone2} alt="iphone2" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="platformWrapper min-h-[200px] pt-12 pb-24">
                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12">
                    <p className="text-xl sm:text-3xl md:text-3xl lg:text-4xl text-center font-semibold mb-12">
                        Why Our Platform is the best?
                    </p>

                    <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-8 mb-32">
                        <div className="pt-8 border-r-textBlack border-r-0 md:border-r-[0.5px] lg:border-r-[0.5px] px-8">
                            <PlaformFeatureCard 
                                title= {"User-Friendly Interface"}
                                content={"Navigate and order effortlessly from our app. you can pay bills, order rides and food."}
                                img={uxIcon}
                            />
                        </div>

                        <div className="pt-8 border-r-textBlack border-r-0 md:border-r-[0.5px] lg:border-r-[0.5px] px-8">
                            <PlaformFeatureCard 
                                title= {"Secure Transactions"}
                                content={"Navigate and order effortlessly from our app. you can pay bills, order rides and food."}
                                img={secureIcon}
                            />
                        </div>

                        <div className="px-8 pt-8">
                            <PlaformFeatureCard 
                                title= {"24/7 Support:"}
                                content={"Navigate and order effortlessly from our app. you can pay bills, order rides and food."}
                                img={fastIcon}
                            />
                        </div>
                    </div>
                </div>

            </div>


            <FlowStepsComp />

            <div className="mt-40">
                <Footer />
            </div>
        </>
    )
}

export default ServicesComp;