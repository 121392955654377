import { FC } from "react";
import Card from "../../../shared/card";

export enum StatCardStatus {
    increase = 'increase',
    decrease = 'decrease'
}

type Props = {
    title:  string;
    icon?: any;
    value: string;
    percentage: number;
    status?: StatCardStatus
}

type StatPillProps = {
    status: StatCardStatus,
    value: number | string
}

const StatusPillComp: FC<StatPillProps> = ({ status, value  }) => {
    return (
        <>
            <div 
                className={`w-max px-4 py-1 text-center rounded-[30px] text-[11px]
                ${status === StatCardStatus.increase ? 'bg-successAlert text-success' : 'bg-dangerAlert text-danger'}`}
            >
                {status === StatCardStatus.increase ? '+' : '-'}
                { value }%
            </div>
        </>
    )
}


const StatCard: FC<Props> = ({
    title,
    icon,
    value,
    percentage,
    status = StatCardStatus.increase
}) => {
    return (
        <>
            <Card type={'sm'} title="" >
                <div className="flex justify-between mb-2">
                    <div>
                        <p className="text-sm text-darkGray">
                            { title }
                        </p>
                    </div>
                    {
                        icon && (
                            <div></div>
                        )
                    }
                </div>

                <div className="flex justify-start gap-4">
                    <div>
                        <p className="text-sm font-bold">{ value }</p>
                    </div>
                    <div>
                        <StatusPillComp 
                            status={status}
                            value={percentage}
                        />
                    </div>
                </div>
            </Card>
        </>
    )
}

export default StatCard;