import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Navbar from '../../app/Navbar';

import './style.css';
import carImg from '../../../assets/imgs/white-car.png';
import foodPlateImg from '../../../assets/imgs/fries-plate.png';
import shopingCartImg from '../../../assets/imgs/shopping-cart.png';
import heroPhone1 from '../../../assets/imgs/hero-phone1.png';
import heroPhone2 from '../../../assets/imgs/hero-phone2.png';
import payBillsImg from '../../../assets/imgs/bank-card.png';
import playStoreIcon from '../../../assets/icons/playstore-purple.svg';
import appStoreIcon from '../../../assets/icons/apple-icon-purple.svg';


interface IHeroCard {
    title: string;
    img: string;
    color?: string;
}

type Props = {
    data: IHeroCard
}


const HeroCard: FC<Props> = ({ data }) => {
    return (
        //Go anywhere
        <>
            <div 
                style={{ backgroundColor: data.color || '#F4E7FF'}} 
                className="rounded-lg relative min-h-[250px] w-[100%] md:w-[75%] lg:w-[65%] pt-8">
                <div className="flex justify-center">
                    <p className="text-[#121212CC] text-xl">{ data.title }</p>
                </div>

                <div className="absolute -bottom-12 left-9">
                    <img 
                        src={data.img} 
                        alt="hero-card-img" 
                        className="w-[300px] h-[300px] object-contain" 
                    />
                </div>
            </div>
        </>
    )
}

const HeroCards: IHeroCard[] = [
    {
        title: 'Go anywhere',
        img: carImg,
        color: '#F4E7FF'
    },
    {
        title: 'Eat anywhere',
        img: foodPlateImg,
        color: '#FCF7C5'
    },
    {
        title: 'Shop anywhere',
        img: shopingCartImg,
        color: '#FCF7C5'
    },
    {
        title: 'Pay any bills',
        img: payBillsImg,
        color: '#F4E7FF'
    },
]


const HeroComp: FC = () => {
    const [currentIndx, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prev => {
                return (prev + 1) % HeroCards.length;
                
            });
        }, 3000)

        return () => {
            clearInterval(interval);
        }
    }, [HeroCards.length])
    return (
        <>
            <div className="wrapper relative min-h-[600px] overflow-hidden">
                {/* <img src={vector2} alt="vector2" className="absolute -top-3 -right-8" />
                <img src={vector2} alt="vector2" className="absolute -top-2 -right-4" />
                <img src={vector1} alt="vector1" className="absolute -bottom-8 left-0" />
                <img src={vector1} alt="vector1" className="absolute -bottom-4 left-0" /> */}

                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12">
                    <div className="my-8">
                        <Navbar />
                    </div>

                    <div className="mx-auto w-10/12 sm:w-10/12 md:w-8/12 lg:4/12 mt-20">
                        <div className="mx-auto w-11/12 sm:w-11/12 md:w-8/12 lg:11/12 flex flex-col gap-4 mb-12">
                            <h1 className="text-center lato-bold text-white text-4xl sm:text-5xl  md:text-5xl lg:text-6xl font-semibold">Your Everyday Needs, Simplified</h1>

                            {/* <div className="my-12 flex justify-start gap-4">
                                <div>
                                    <img src={groupAvatar} alt="group avatar" />
                                </div>
                                <div>
                                    <p className="text-white text-xs md:text-sm lg:text-sm">
                                    Join with 1k+ Users and enjoy rides, food, groceries and pay bills in just a click
                                    </p>
                                </div>
                            </div> */}

                            <div className="flex justify-around gap-4 mt-8">
                                <div className="">
                                    <Button
                                         sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#ffffff',
                                            color: '#5C188B',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={appStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on apple store</span>
                                    </Button>
                                </div>
                                <div className="">
                                    <Button
                                        sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        className="relative"
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#ffffff',
                                            color: '#5C188B',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={playStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on play store</span>
                                    </Button>

                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center gap-4 mt-12 md:mt-0 lg:mt-0">
                            <img src={heroPhone1} alt="heroPhone1" />
                            <img src={heroPhone2} alt="heroPhone2" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroComp;