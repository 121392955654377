import { FC } from "react";
import TopbarProfile from "./topbar-profile";


const Topbar: FC = () => {
    return (
        // dark:bg-slate-700 dark:text-white
        <div className="w-full bg-white text-black py-4">
            <div className="flex justify-between gap-4">
                <div>
                    <TopbarProfile />
                </div>
                <div className="flex-grow"></div>
            </div>
        </div>
    )
}

export default Topbar;