import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Navbar from '../../app/Navbar';

import './style.css';
import carImg from '../../../assets/imgs/white-car.png';
import foodPlateImg from '../../../assets/imgs/fries-plate.png';
import shopingCartImg from '../../../assets/imgs/shopping-cart.png';
import groupAvatar from '../../../assets/imgs/avatar-group.png';

import payBillsImg from '../../../assets/imgs/bank-card.png';
import playStoreIcon from '../../../assets/icons/playstore-purple.svg';
import appStoreIcon from '../../../assets/icons/apple-icon-purple.svg';


interface IHeroCard {
    title: string;
    img: string;
    color?: string;
}



const HeroComp: FC = () => {
    return (
        <>
            <div className="wrapper relative min-h-[600px] overflow-hidden">

                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12">
                    <div className="my-8">
                        <Navbar />
                    </div>

                    <div className="mx-auto w-11/12 sm:w-10/12 md:w-9/12 lg:9/12 mt-16 mb-24">
                        <div className="mx-auto w-11/12 flex flex-col gap-4 mb-12">
                            <h1 className="text-center lato-bold text-white text-4xl sm:text-5xl  md:text-5xl lg:text-6xl font-semibold">
                                All-in-One Solution for Food, Rides, Groceries & Bills
                            </h1>

                            <div className="my-12 flex justify-center gap-4">
                                <div>
                                    <img src={groupAvatar} alt="group avatar" />
                                </div>
                                <div>
                                    <p className="text-white text-xs md:text-sm lg:text-sm w-[250px]">
                                    Join with 1k+ Users and enjoy rides, food, groceries and pay bills in just a click
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-center gap-4 mt-4">
                                <div className="">
                                    <Button
                                         sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#ffffff',
                                            color: '#5C188B',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={appStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on apple store</span>
                                    </Button>
                                </div>
                                <div className="">
                                    <Button
                                        sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        className="relative"
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#ffffff',
                                            color: '#5C188B',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                            width: 'max-content'
                                        }}
                                        startIcon={
                                            <img src={playStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on play store</span>
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroComp;