import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import AdminLayout from "../../../shared/admin-layout";
import InputField from "../../../components/form/InputField";
import Card from "../../../shared/card";


const CreateEditUserPage: FC = () => {
    const [formValues, setFormValues] = useState({
        fullName: '',
        email: '',
        gender: '',
        phone: "",
        address: ''
    });

    const validateForm = () => Yup.object({
        fullName: Yup.string().required('FullName is required'),
        email: Yup.string().required('Email is required'),
        phone: Yup.string().required('Phone is required'),
        address: Yup.string().required('Address is required'),
    })

    const { values, errors, touched, handleSubmit, handleChange, setSubmitting, isSubmitting, } = useFormik({
        initialValues: formValues,
        isInitialValid: true,
        validationSchema: validateForm(),
        onSubmit: (values) => {
            const payload = {...values}
        }
    });

    useEffect(() => {
        setFormValues({
            fullName: '',
            email: '',
            gender: '',
            phone: "",
            address: ''
        })
            
    }, []);

    return (
        <>
            <AdminLayout>
                <div className="w-full min-h-screen mt-4 sm:w-11/12 md:w-9/12 lg:w-7/12 mx-auto">

                    <Card type="sm">
                        <p className="text-lg font-semibold mb-8">Add New User</p>
                        <form>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                                <div className="my-2">
                                    <InputField
                                        type={'text'}
                                        name='fullName'
                                        label='Full Name'
                                        labelPosition={'out'}
                                        value={values.fullName}
                                        showBorder={false}
                                        isError={(touched.fullName && errors.fullName) ? true : false}
                                        errMsg={errors && errors.fullName}
                                        placeholder='Enter your Full name'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="my-2">
                                    <InputField
                                        type={'text'}
                                        name='gender'
                                        label='Gender'
                                        labelPosition={'out'}
                                        value={values.gender}
                                        showBorder={false}
                                        isError={(touched.gender && errors.gender) ? true : false}
                                        errMsg={errors && errors.gender}
                                        placeholder='Enter your gender'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="my-2">
                                    <InputField
                                        type={'text'}
                                        name='email'
                                        label='Email Address'
                                        labelPosition={'out'}
                                        value={values.email}
                                        showBorder={false}
                                        isError={(touched.email && errors.email) ? true : false}
                                        errMsg={errors && errors.email}
                                        placeholder='Enter your Email'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="my-2">
                                    <InputField
                                        type={'text'}
                                        name='phone'
                                        label='Phone Number'
                                        labelPosition={'out'}
                                        value={values.phone}
                                        showBorder={false}
                                        isError={(touched.phone && errors.phone) ? true : false}
                                        errMsg={errors && errors.phone}
                                        placeholder='Enter your phone number'
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="my-4">
                                <InputField
                                    type={'text'}
                                    name='address'
                                    label='Address'
                                    labelPosition={'out'}
                                    value={values.address}
                                    showBorder={false}
                                    isError={(touched.address && errors.address) ? true : false}
                                    errMsg={errors && errors.address}
                                    placeholder='Enter your address'
                                    onChange={handleChange}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </AdminLayout>
        </>
    )
}

export default CreateEditUserPage;