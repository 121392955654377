import { FC } from "react";


export enum StatusPillEnum {
    completed = 'completed',
    pending = 'pending',
    failed = 'failed'
} 

type StatPillProps = {
    status: StatusPillEnum,
    value: number | string
}

const StatusPill: FC<StatPillProps> = ({ status, value  }) => {
    return (
        <>
            <div 
                className={`w-max px-4 py-1 text-center rounded-[30px] text-[11px]
                ${status === StatusPillEnum.completed 
                    ? 'bg-successAlert text-success' 
                    : status === StatusPillEnum.pending
                    ?  'bg-warningAlert text-warning'
                    : 'bg-dangerAlert text-danger'}`}
            >
                { value }
            </div>
        </>
    )
}

export default StatusPill;