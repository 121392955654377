import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import * as Yup from 'yup';

import logo from '../../../assets/imgs/logo-purple.png';
import authBgImg from '../../../assets/imgs/auth-background.png';
import authFooterImg from '../../../assets/imgs/auth-footer.png';
import InputField from "../../form/InputField";
import AppButton from "../../app/AppButton";
import { getItem } from "../../../utils";
import { ApiResponse } from "../../../models";
import { OPEN_NOTIFICATION } from "../../../store/notification";
import { ForgotPasswordService } from "../../../services";
import Card from "../../../shared/card";

type Props = {
  changeStep: (data: number) => any
}


const ForgotPasswordComp3 = ({ changeStep }: Props) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: ''
  });

  const loginFormValidation = () => Yup.object({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords must match')
  });

  const handleSelectChange = (fieldName: string, value: any) => {
    handleChange({target: {name: fieldName, value}});
  }

  const { values, errors, touched, isSubmitting, setSubmitting, handleSubmit, handleChange } = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: loginFormValidation(),
    onSubmit: (values) => {
      const clientEmail = getItem('xxid');
      const data = { email: clientEmail, password: values.password};
      ForgotPasswordService(data)
        .then((res: AxiosResponse<ApiResponse>) => {
          setSubmitting(false);
          const { success, message } = res.data;
          if(success){
            dispatch(OPEN_NOTIFICATION({ message }))
            changeStep(4);
          }
        }).catch((err: AxiosError<ApiResponse>) => {
          setSubmitting(false);
          changeStep(3);
          if(err.response?.data.message){
            dispatch(OPEN_NOTIFICATION({ message: err.response?.data.message }))
          }
      })
    }
  })


  useEffect(() => {
    setFormValues({
      password: '',
      confirmPassword: ''
    })
  }, []);

  return (
    <>
      <div className="w-full h-full relative" style={{
          background: `url(${authBgImg})`,
          backgroundSize: "cover",
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
      }}>
          <div className="w-full min-h-screen sm:w-11/12 md:w-9/12 lg:w-5/12 mx-auto pt-16">
            <Card title='' type={'sm'}>
              {/* Header */}
              <div className="w-full p-4">
                <div className="mx-auto w-8/12">
                  <div className="flex justify-center">
                    <Link to={'/'}>
                      <img src={logo} width={'100px'} height={"60px"} alt="" />
                    </Link>
                  </div>
                  <h1 className="text-2xl lato-bold font-bold text-textBlack text-center">Forgot your password?</h1>
                  <p className="text-sm lato-regular text-textBlack mb-4 text-center">
                    Go back to 
                    <Link to={'/admin/login'} className="text-primary font-bold mx-2">Log in</Link>
                  </p>
                  <p className="text-sm lato-regular text-textBlack mb-4 text-center">
                    Enter your registered email address and you will receive an email with password reset code
                  </p>
                </div>


                {/* Form */}
              <div className="mt-12">
                <form onSubmit={handleSubmit}>
                  <InputField 
                    name="password"
                    type="password"
                    label="New Password"
                    labelPosition={'out'}
                    placeholder="Please enter your new password"
                    value={values.password}
                    isError={(touched.password && errors.password) ? true : false}
                    errMsg={errors && errors.password}
                    onChange={(value) => handleSelectChange('password', value)}
                  />

                  <InputField 
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    labelPosition={'out'}
                    placeholder="Please enter your password"
                    value={values.confirmPassword}
                    isError={(touched.confirmPassword && errors.confirmPassword) ? true : false}
                    errMsg={errors && errors.confirmPassword}
                    onChange={(value) => handleSelectChange('confirmPassword', value)}
                  />

                  <div className="mt-[25%] mb-4">
                    <div className="flex justify-center">
                      <AppButton 
                        width="full"
                        fill="fill"
                        btnText="Confirm"
                        bgColor="primary"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                      />
                    </div>
                  </div>
                  
                </form>

              </div>
              {/* Form */}
              </div>
              {/* Header */}
            </Card>
        </div>

        <div className="absolute bottom-0 z-10">
          <img src={authFooterImg} width={"100%"} alt="footer" />
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordComp3;