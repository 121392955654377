
export interface IAppStep {
    serialNumber: number; 
    value: string;
}

export const AppSteps: IAppStep[] = [
    {
        serialNumber: 1,
        value: "Download the app from Google play store or Apple store."
    },
    {
        serialNumber: 2,
        value: "Choose your service. Rides, food, groceries, or bills."
    },
    {
        serialNumber: 3,
        value: "Customize your order or select your destination."
    },
    {
        serialNumber: 4,
        value: "Confirm and track your service in real-time."
    },
    {
        serialNumber: 5,
        value: "Enjoy seamless service delivery."
    },

];